@charset "utf-8";

// Theme variables
$family-sans-serif: 'Proxima Nova';
$body-background-color: #f9f9f9;

$primary: #0090BA;
$text: #3e3e3e;
$info: $primary;
$link: $primary;

$tabs-toggle-link-active-background-color: $link;
$tabs-toggle-link-active-border-color: $link;

$input-arrow: #757e87;

// Tabs specific theme variables
$tabs-border-bottom-width: 2px;
$tabs-link-active-color: $text;
$tabs-link-active-border-bottom-color: $text;
$tabs-link-hover-border-bottom-color: #afafaf;

// Pagination
$pagination-current-background-color: $link;
$pagination-focus-border-color: $link;
$pagination-current-border-color: $link;

// Dropdown
$dropdown-item-active-background-color: #0090BA;

// TODO: Go over imports and import only what we need from Bulma
@import 'bulma/bulma.sass';

.tag:not(body).is-link {
  background-color: #ACF21F;
  color: #000000;
}

// Extending pagination
.pagination-list {
  flex-grow: 0;
  .break-me a {
    color: $link;
  }
}

// Extending Tab Component
.toggle-bar-component {
  .tabs.is-toggle li {
    background: #ffffff;
  }
  .tabs.is-toggle li.is-active a,
  .tabs.is-toggle li a {
    font-size: 14px;
  }
}

.dropdown {
  display: block;
  margin: 15px;

  .button {
    font-size: 0.88rem;
    width: 100%;
    justify-content: space-between;
  }

  .dropdown-menu {
    width: 100%;
  }
}
