/* Checkboxes - Taken from Materialize.css
   ========================================================================== */

/* Remove default checkbox */
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

// Checkbox Styles
[type='checkbox'] {
  // Text Label Style
  + div {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    display: inline-block;
    min-height: 25px;
    line-height: 20px;
    font-size: 0.9rem;
    user-select: none;
  }

  /* checkbox aspect */
  + div:before,
  &:not(.filled-in) + div:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    z-index: 0;
    border: 2px solid $radio-empty-color;
    border-radius: 1px;
    margin-top: 3px;
    transition: 0.2s;
  }

  &:not(.filled-in) + div:after {
    border: 0;
    transform: scale(0);
  }

  &:not(:checked):disabled + div:before {
    border: none;
    background-color: $input-disabled-color;
  }

  // Focused styles
  &:focus + div:after {
    transform: scale(1);
    border: 0;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

[type='checkbox']:checked {
  + div:before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: $radio-border;
    border-bottom: $radio-border;
    transform: rotate(40deg);
    backface-visibility: hidden;
    transform-origin: 100% 100%;
  }

  &:disabled + div:before {
    border-right: 2px solid $input-disabled-color;
    border-bottom: 2px solid $input-disabled-color;
  }
}

/* Indeterminate checkbox */
[type='checkbox']:indeterminate {
  + div:before {
    top: -11px;
    left: -12px;
    width: 10px;
    height: 22px;
    border-top: none;
    border-left: none;
    border-right: $radio-border;
    border-bottom: none;
    transform: rotate(90deg);
    backface-visibility: hidden;
    transform-origin: 100% 100%;
  }

  // Disabled indeterminate
  &:disabled + div:before {
    border-right: 2px solid $input-disabled-color;
    background-color: transparent;
  }
}

// Filled in Style
[type='checkbox'].filled-in {
  // General
  + div:after {
    border-radius: 2px;
  }

  + div:before,
  + div:after {
    content: '';
    left: 0;
    position: absolute;
    /* .1s delay is for check animation */
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
      height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    z-index: 1;
  }

  // Unchecked style
  &:not(:checked) + div:before {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 10px;
    transform: rotateZ(37deg);
    transform-origin: 100% 100%;
  }

  &:not(:checked) + div:after {
    height: 20px;
    width: 20px;
    background-color: white;
    border: 2px solid $radio-empty-color;
    top: 0px;
    z-index: 0;
  }

  // Checked style
  &:checked {
    + div:before {
      top: 0;
      left: 1px;
      width: 9px;
      height: 12px;
      border-top: 2px solid transparent;
      border-left: 2px solid transparent;
      border-right: 2px solid $input-background;
      border-bottom: 2px solid $input-background;
      transform: rotateZ(37deg);
      transform-origin: 100% 100%;
    }

    + div:after {
      top: 0;
      width: 20px;
      height: 20px;
      border: 2px solid $secondary-color;
      background-color: $secondary-color;
      z-index: 0;
    }
  }

  // Focused styles
  &:focus + div:after {
    border-radius: 2px;
    border-color: $radio-empty-color;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:checked:focus + div:after {
    border-radius: 2px;
    background-color: $secondary-color;
    border-color: $secondary-color;
  }

  // Disabled style
  &:disabled:not(:checked) + div:before {
    background-color: transparent;
    border: 2px solid transparent;
  }

  &:disabled:not(:checked) + div:after {
    border-color: transparent;
    background-color: $input-disabled-solid-color;
  }

  &:disabled:checked + div:before {
    background-color: transparent;
  }

  &:disabled:checked + div:after {
    background-color: $input-disabled-solid-color;
    border-color: $input-disabled-solid-color;
  }
}
