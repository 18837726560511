@import url('https://fonts.googleapis.com/css?family=Material+Icons|IBM+Plex+Sans:200,300,400,500,700');
@import 'bulmaStyle';

// Materialize.css form components
$secondary-color: #0090BA !default;
$radio-fill-color: #0090BA !default;
$radio-empty-color: #7a7a7a !default;
$radio-border: 1px solid $radio-fill-color !default;
$input-background: #fff !default;
$input-disabled-solid-color: #949494 !default;
@import '_checkboxes';
@import '_radio-buttons';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;

  font-family: 'Proxima Nova', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
}

#root {
  display: flex;
  flex-direction: column;
}

.MuiAutocomplete-loading {
  font-size: 14px;
}

.DateRangePicker .DateInput_input__small {
  font-size: 16px !important;
}

